import { DefaultTeaser as ChameleonDefaultTeaser } from '@mediahuis/chameleon-react';
import { type FC, type ReactElement, type ReactNode, type RefObject, useRef } from 'react';

import { defaultTeaserImageRatio } from '@hubcms/brand';
import type { TeaserImageFormat } from '@hubcms/domain-images';
import { isTeaserImage } from '@hubcms/domain-teaser';
import type { TrackingData } from '@hubcms/domain-tracking';
import { getThemeDataAttributes } from '@hubcms/utils-theme';

import { inheritDefaultTeaserVerticalImageSizes } from '../../utils/inheritDefaultTeaserVerticalImageSizes';
import { NativeTeaserImage } from '../NativeTeaserImage';
import { NativeTeaserUrlImage } from '../NativeTeaserImage/NativeTeaserUrlImage';
import { TeaserLink } from '../TeaserLink';
import { TeaserVideoPreview } from '../TeaserVideoPreview';

import { getChameleonProps } from './getChameleonProps';
import type { DefaultTeaserProps } from './types';

const getTeaserImage = ({
  isImageHidden = false,
  videoPreviewId,
  teaserRef,
  ...props
}: Omit<DefaultTeaserProps, 'imageFormat'> & {
  imageFormat: TeaserImageFormat;
  videoPreviewId?: string;
  teaserRef?: RefObject<HTMLElement>;
}): ReactElement | null => {
  if (isImageHidden) {
    return null;
  }

  let imageComponent: ReactElement | null = null;
  if (props.image) {
    const sizes = inheritDefaultTeaserVerticalImageSizes(props.orientation, props.size, props.imageSizes);
    imageComponent = isTeaserImage(props.image) ? (
      <NativeTeaserImage image={props.image} format={props.imageFormat} sizes={sizes} isPriority={props.isImagePriority} />
    ) : (
      // simple image variant for teaser images where the image is a simple url - introduced for default teasers in the RSS article list
      <NativeTeaserUrlImage url={props.image} format={props.imageFormat} sizes={sizes} isPriority={props.isImagePriority} />
    );
  }

  return (
    <>
      {imageComponent}
      {videoPreviewId && teaserRef && <TeaserVideoPreview previewId={videoPreviewId} teaserRef={teaserRef} />}
    </>
  );
};

const getTeaserLink = (link: ReactNode, isExternal: boolean, trackingData: TrackingData | undefined) => {
  return typeof link === 'string' && trackingData ? (
    <TeaserLink url={link} isExternal={isExternal} trackingData={trackingData} />
  ) : (
    link
  );
};

const DefaultTeaser: FC<DefaultTeaserProps> = ({ imageFormat = defaultTeaserImageRatio, videoPreviewId, ...props }) => {
  const teaserRef = useRef<HTMLDivElement>(null);
  const link = getTeaserLink(props.link, props.isExternal, props.trackingData);
  const teaserImage = getTeaserImage({
    ...props,
    imageFormat,
    videoPreviewId,
    teaserRef,
  });
  const chameleonProps = getChameleonProps({ ...props, imageFormat }, teaserImage);
  const themeDataAttributes = props.theme ? getThemeDataAttributes(props.theme) : null;

  return (
    <ChameleonDefaultTeaser
      ref={teaserRef}
      {...chameleonProps}
      link={link}
      className={props.className}
      data-article-id={props.id}
      data-list-id={props.list?.id}
      {...themeDataAttributes}
    />
  );
};

export default DefaultTeaser;
