import cx from 'classnames';

import type { ImageSizes, TeaserImageFormat } from '@hubcms/domain-images';
import { createImageSizes } from '@hubcms/utils-images';

import styles from './native-teaser-image.module.scss';

type Props = {
  url: string;
  format: TeaserImageFormat;
  isPriority?: boolean;
  sizes?: ImageSizes;
  className?: string;
};

export function NativeTeaserUrlImage({ url, format, sizes, isPriority = false, className }: Props) {
  return (
    <img
      src={url}
      alt={url.split('/').pop() ?? ''}
      className={cx(className, styles.nativeTeaserImage, format)}
      sizes={createImageSizes(sizes)}
      loading={isPriority ? 'eager' : 'lazy'}
    />
  );
}
